.primary-button {
    background-color: #2680EB;
    color: #fff;
  }
  
  .primary-button:hover {
    background-color: #1C84FF;
  }
  
  .primary-outline-button {
    background-color: white;
    border: solid 1px #2680EB !important; 
    color: #2680EB;
  }
  
  .primary-outline-button:hover {
    background-color: #cbdff84d !important;
  }

  .x-button {
    position: absolute;
    cursor: pointer;
    color: #555;
    background-color: #00000000;
    border: none;
    font-size: 28px;
  }
  
  .x-button:hover {
    color: #000000;
  }