.page-wrapper-circles *,
.page-wrapper-circles *:before,
.page-wrapper-circles *:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  direction: rtl;
}

.background body {
  background-color: #F5F6FA;
}

.background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}

.shape:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}

.shape:last-child {
  background: linear-gradient(#1845ad81, #23a2f657);
  right: -30px;
  bottom: -120px;
}

.submit-form {
  height: 100%;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(20px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.3);
  padding: 50px 35px;
  z-index: 999;
}

.submit-form * {
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}

.submit-form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.submit-form label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}

.submit-form input {
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
}

.submit-form ::placeholder {
  color: #00000088;
  font-size: 12px;
}

.submit-form button {
  margin-top: 50px;
  width: 100%;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

a.submit-form-link {
  display: block;
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #23a2f6;
  text-decoration: none;
}

a.submit-form-link:hover {
  cursor: pointer;
}