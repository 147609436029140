.chats-container {
  width: 25%;
  height: auto;
  margin-right: 20px;
  padding: 2%;
  direction: rtl;
  background-color: white;
  position: relative;
  border-radius: 20px;
}

/*CHATS HEADER CONTAINER */
.chats-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.chats-header-container .title {
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 600;
  justify-content: flex-start;
}

.chats-header-container .add-contact-button {
  align-items: center;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin-right: auto;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.add-contact-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -90%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 18px;
  z-index: 999;
}
.add-contact-form.add-nickname-form .x-button{
  left: 0;
  margin: 7px;
  padding: 0;
  text-align: left;
}
.add-contact-form.add-nickname-form input {
  padding: 2%;
}

.phone-form {
  direction: ltr;
}

 .title-add{
  font-weight: 600;
  margin-bottom: 12px;
  align-items: center;
  text-align: center;
}

.add-contact-form .x-button{
  top: 3px;
  left: -35px;
}

.add-contact-form input {
  width: 95%;
  padding: 3%;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 5% 0 3%;
}

.add-contact-form input:focus {
  outline: none;
}

.add-contact-form button {
  width: 48%;
  align-items: center;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  margin: 2% 1% 0;
  padding: 5px 25px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out;
}

/*CHATS SEARCH CONTAINER */
.chats-search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  color: #555;
}

.chats-search-container input {
  width: 85%;
  height: 45px;
  border-radius: 50px;
  background-color: #F5F6FA;
  border: none;
  padding-right: 14%;
  text-indent: 15px;
}

.chats-search-container input:focus {
  outline: none;
  border: none;
}

/*CHATS FILTER CONTAINER */
.chats-filter-container {
  width: 100%;
  margin-bottom: 20px;
}

.chats-filter-container p {
  font-size: 12px;
  margin-bottom: 10px;
  color: #555;
}

.filter-option {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #F5F6FA;
  border: none;
  padding-right: 14%;
  
}

.chats-filter-container select:focus {
  outline: none;
  border: none;
}

.chats-filter-container select::-ms-expand {
  display: none;
}

.chats-filter-container select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%23555' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.filter-option option {
  background-color: white;
  border-radius: 18px;
  border: none;
  padding: 5px;
  color: #333;
  overflow-y: scroll;
}


/*CHATS LIST CONTAINER */
.chats-list-container {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  max-height: calc(98vh - 350px);
  overflow-y: auto;
}

.chats {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.chats div {
  margin-bottom: 10px;
}

.chats::-webkit-scrollbar {
  width: 6px;
}

.chats::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chats::-webkit-scrollbar-thumb {
  background: #d4d4d5;
  border-radius: 10px;
}

.chats::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 0 0 0 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.chat-item:hover {
  background-color: #ecf1fa;
  border-bottom: none;
  border-right: 4px solid #2680eb;
  text-transform: none;
  transition: color .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out, border .08s ease-in-out;
}

.chat-item:active {
  background-color: #ecf1fa;
  border-bottom: none;
  border-right: 4px solid #2680eb;
  text-transform: none;
  transition: color .15s ease-in-out, background .15s ease-in-out, opacity .15s ease-in-out, border .08s ease-in-out;
}

.chat-link {
  text-decoration: none;
  color: inherit;
}

.chat-info {
  flex: 1;
  margin: 0 4%;
}

.chat-list-title {
  font-weight: 600;
  font-size: 14px;
}

.last-message {
  color: #777;
  font-size: 14px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.last-message .sent-status {
  color: #777;
  font-size: 14px;
}

.last-message .delivered-status {
  color: #777;
  font-size: 18px;
}

.last-message .read-status {
  color: #2680eb;
  font-size: 18px;
}

.bold-message {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8)
}

.last-message-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.last-message-date {
  color: #999;
  font-size: 12px;
}

.admin-tag {
  font-size: 14px;
  font-weight: 400;
}

.chats-list-container .no-results-message {
  font-size: 18px;
  color: #555;
}

.flag {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px 0 10px;
}

.circular-flag {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 3px solid #cacaca;
}

.circular-flag span {
  font-size: 200px;
}