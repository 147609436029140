.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  direction: rtl;
}

.header-right {
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 800;
  /* margin-right: 14%; */
}
.back-btn {
  align-items: center;
  display: none;
}
.admin-name {
  margin: 0;
}

.header-left button {
  margin-left: 8px;
}

.header-button {
  align-items: center;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-size: 90%;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 10px 34px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out;
}

.add-admin-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  z-index: 999;
  width: 30%;
}

.add-admin-form .x-button {
  top: 3px;
  left: -90px;
}

.add-admin-form .title {
  font-weight: 600;
  margin-bottom: 12px;
  align-items: center;
  text-align: center;
}

.add-admin-form input {
  width: 94%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.add-admin-form input:focus {
  outline: none;
}

.add-admin-form label {
  display: flex;
  margin-bottom: 10px;
}

.add-admin-form .check-admin {
  height: 16px;
  width: 16px;
  cursor: pointer;
  margin-left: 12px;
}

.add-admin-form button {
  width: calc(48% - 5px);
  margin: 0 5px;
  align-items: center;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 20px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out;
}

.password-input-container {
  position: relative;
}

.password-input-container input {
  width: 50%;
}

.password-input-container button {
  width: 42%;
}