.chat-window-container {
  flex: 1;
  direction: rtl;
  margin: 0 3% 0 1%;
  height: auto;
  overflow: hidden;
}

.chat-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: white;
  padding: 2%;
  border-radius: 10px;
  flex-wrap: wrap;
}

.chat-title {
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 700;
  justify-content: flex-start;
}

.chat-title-container .phone-number {
  font-size: 12px;
  color: #a7a7a7;
}
.form-container {
  display: flex;

}

.chat-title-button {
  align-items: center;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  margin-right: auto;
  padding: 8px 14px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}


.change-admin-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  max-height: 70%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 22px;
  z-index: 999;
}


.change-admin-form .title-add {
  font-size: 25px;
  margin-bottom: 2%;
}

.change-admin-form table {
  width: 100%;
}

.change-admin-form table td {
  padding: 12px 8px;
  border-bottom: 1px solid #55555533;
}

.change-admin-form table a {
  color: white;
  cursor: pointer;
  margin-right: 8px;
  background-color: #007bffb4;
  padding: 2%;
  border-radius: 6px;
}

.change-admin-form table a:hover {
  background-color: #007bff;
}

.change-admin-form table .blocked {
  background-color: rgba(255, 0, 0, 0.5);
  padding: 8% 16%;
}

.change-admin-form table .blocked:hover {
  background-color: rgba(175, 0, 0, 0.678);
}

.change-admin-form table .unblocked {
  background-color: rgba(0, 128, 0, 0.5);
}

.change-admin-form table .unblocked:hover {
  background-color: rgba(0, 128, 0, 0.747);
}

.change-admin-form .x-button {
  top: 15px;
  left: 15px;
  font-size: 18px;
}

.change-admin-form::-webkit-scrollbar {
  width: 6px;
}

.change-admin-form::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.change-admin-form::-webkit-scrollbar-thumb {
  background: #d4d4d5;
  border-radius: 5px;
}

.change-admin-form::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.chat-messages-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  background-color: white;
  height: calc(99vh - 270px);
  border-radius: 10px;
  padding: 1% 3%;
  margin-bottom: 16px;
}

.chat-messages {
  overflow-y: scroll;
}

.chat-messages::-webkit-scrollbar {
  width: 6px;
}

.chat-messages::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-messages::-webkit-scrollbar-thumb {
  background: #d4d4d5;
  border-radius: 5px;
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7;
}

.chat-messages-container {
  padding: 10px;
}

.chat-messages {
  display: flex;
  flex-direction: column;
}

.message {
  margin-bottom: 6px;
  margin: 0 10px 6px;
  padding: 10px;
  border-radius: 12px;
  min-width: 15%;
  max-width: 30%;
  word-wrap: break-word;
  position: relative;
}

.message.sent {
  align-self: flex-start;
  background-color: #007bff;
  color: white;
}

.message.received {
  align-self: flex-end;
  background-color: #e7e7e7;
  color: black;
}

.message.received .timestamp {
  color: #00000098;
  margin-top: 2%;
}

.message-status-timestamp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.message .timestamp {
  font-size: 11px;
  color: #ffffffc2;
}

.message-content {
  font-size: 14px;
  line-height: 1.5;
  position: relative;
}

.file-message {
  border-radius: 14px;
  min-width: 15%;
  max-width: 100%;
}

.message-status {
  font-size: 18px;
  display: inline;
  margin-left: 5px;
  margin-top: 2px;
}

.message-status .sent-color {
  color: #ffffff80;
}

.message-status .delivered-color {
  color: #ffffff80;
}

.message-status .read-color {
  color: #ffffff;
}

.message-status .failed-color {
  color: rgba(255, 0, 0, 0.658);
}

.file-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: 8px;
  color: white;
}

.pdf-icon {
  font-size: 50px;
}

.file-icon {
  font-size: 50px;
}

.file-name {
  margin-left: 4px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  width: -webkit-fill-available;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download-button {
  position: absolute;
  top: 30%;
  transform: translate(-10%, 50%);
  padding: 5px 10px;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 22px;
}

.download-button span {
  font-size: 12px;
  color: white;
  text-decoration: none;
  font-weight: 300;
}

.download-button .download-icon {
  vertical-align: middle;
  margin-left: 5px;
}

.chat-input-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 45px;
  padding: 1% 0;
  height: 40px;
}

.chat-input-container textarea {
  margin: 0 3%;
  border: none;
  width: 80%;
  height: 80%;
  resize: none;
}

.chat-input-container textarea .icon {
  display: none;
}

.icon {
  font-size: 18px;
  cursor: pointer;
}

.send-file-icon {
  color: #B0B0B0;
  margin-right: 12px;
}

.submit-form-icon {
  color: #B0B0B0;
  margin-right: 24px;
  font-size: 22px;
}

.icon-send-message {
  position: static;
  height: auto;
  background-color: #007bff;
  align-items: center;
  border-radius: 50%;
  border: none;
  color: white;
  cursor: pointer;
  justify-content: center;
  padding: 7px;
  transition: background-color 0.3s ease;
  margin-left: 20px;
}
.audio-start-record {
  margin-left: 10px;
}
.chat-input-container input:focus {
  outline: none;
  border: none;
}


.submission-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35%;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 18px;
  margin: 0 2%;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}

.submission-form .x-button {
  top: 8px;
  left: -90px;
}

.submission-form .center-text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 600;
}

.submission-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.submission-form input,
.submission-form select {
  width: calc(95% - 20px);
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submission-form select {
  margin-right: 3%;
  width: 95%;
}

.submission-form button {
  width: calc(45% - 5px);
  align-items: center;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out;
  margin-inline-start: 20px;
}

.chat-main-container.blur {
  pointer-events: none;
  filter: blur(12px);
}

.no-chat-selected {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: #000000;
  z-index: 995;
}

.file-preview-form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 18px;
  z-index: 999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow-x: hidden;
  overflow-y: hidden;
}

.file-preview-form div {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.file-preview-form .x-button {
  top: 10px;
  left: -50px;
}

.file-preview-form img,
.file-preview-form video,
.file-preview-form audio {
  max-width: 100%;
  margin: 10px 0;
  border-radius: 8px;
}

.file-preview-form video {
  height: 70vh;
}

.file-preview-form img {
  max-width: 50%;
  max-height: 68vh;
}

.file-preview-form button {
  width: calc(40% - -8px);
  margin: 0 5px;
  align-items: center;
  border-radius: 10px;
  border-style: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 20px;
  text-align: center;
  text-transform: none;
  transition: color .13s ease-in-out, background .13s ease-in-out, opacity .13s ease-in-out;
}

#waveform{
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 998;
}


.audio-recorder {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-start-recording {
  position: static;
  /* margin-right: 20px; */
  color: #0069da;
  height: 25px;
  /* left: 20%; */
  /* top: 50%; */
  /* margin-left: 50%; */
}

.icons-record {
  position: relative;
  display: block;
  margin-bottom: 5px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  animation-fill-mode: both;
  cursor: pointer;
  font-size: 20px;
}

.icon-speed-fast {
  animation: fadeInFast 0.5s linear;
  color: #0069da;
}

.icon-speed-medium {
  animation: fadeInMedium 0.7s linear;
  color: rgba(0, 0, 0, 0.7);
}

.icon-speed-slow {
  animation: fadeInSlow 0.9s linear;
}

.icon-recording {
  color: red;
  animation: recordingBlink 1s infinite alternate;
}
.icon-emoji {
  font-size: 27px;
  margin-left: 10px;
  color: #007bff;
}
.emoji-picker-container aside {
  bottom: 0px;
  left: 0px;
  position: absolute;
}

@media (max-width: 1024px) {
  .no-chat-selected {
    left: 25%;
  }
}

@keyframes recordingBlink {
  0% {
    color: rgba(255, 0, 0, 0.123);
  }

  100% {
    color: red;
  }
}

@keyframes fadeInFast {
  0% {
    opacity: 0;
    left: -100px;
  }

  75% {
    opacity: 0.5;
    left: 0px;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInMedium {
  0% {
    opacity: 0;
    left: -100px;
  }

  75% {
    opacity: 0.5;
    left: 0px;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInSlow {
  0% {
    opacity: 0;
    left: -100px;
  }

  75% {
    opacity: 0.5;
    left: 0px;
  }

  100% {
    opacity: 1;
  }
}