@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Readex+Pro:wght@160..700&display=swap');

* {
  font-family: 'Inter';
}

body {
  margin: 1% 3%;
  background-color: #F5F6FA;
}

.container {
  display: flex;
}

.chat-page-container {
  display: flex;
  flex-direction: row;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.chat-window-container {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  backdrop-filter: blur(10px);
}
.header-left {
  display: flex;
}
.pdf-document canvas {

  width: 100% !important;
  height: 100% !important;
  max-height: 365px;
}

.pdf-document .react-pdf__Page__textContent {
  height: auto !important;
} 

.pdf-document .react-pdf__Page__textContent .textLayer {
  height: 435px;
} 


@media (min-width: 100px) and (max-width: 530px) {
  /* body {
    width: 180%;
  } */
  html {
    -ms-overflow-style: none;
  }
  .header {
    padding: 5px;
  }

  .header-right,
  .header-left {
    font-size: 16px;
  }

  .chat-page-container {
    flex-direction: row;
    height: auto;
    justify-content: center;
  }

  .chats-container {
    width: 80%;
    margin: 1%;
  }

  .chat-window-container {
    margin: 1%;
  }
  .hide-mobile {
    display: none;
  }
  .back-btn {
    display: flex;
  }

  .message{
    max-width: 50%;
  }

  .header-button {
    padding: 10px 20px;
  }

  .add-admin-form {
    width: 60%;
  }

  .add-admin-form .x-button {
    top: 3px;
    left: -40px;
  }

  .add-admin-form button {
    width: 45%;
  }

  .password-input-container input {
    width: 45%;
  }
  
  .password-input-container button {
    width: 40%;
  }

  .change-admin-form {
    width: 80%;
  }

  .file-preview-form {
    top: 50%;
    left: 50%; 
    width: 80%;
  }

  .submission-form{
    top: 25%;
    left: 25%;
  }

  .submission-form .x-button {
    top: 8px;
    left: -40px;
  }

  .submission-form select {
    width: 65%;
  }

  .submission-form button {
    width: calc(40% - 5px);
  }

  .chat-input-container input{
    width: 57%;
  }

  .file-preview-form .x-button {
    top: 10px;
    left: -30px;
  }

  .submit-form {
    height: 100%;
    width: 80%;
    transform: translate(-50%, -50%);
  }

  .chats-container {
    height: inherit;
    padding-bottom: 25px;
  }


  .download-button {
    transform: translate(-10%, 50%);
  }
  
  .shape:last-child {
    right: 15px;
  }
  
  .background {
    width: 360px;
  }
}


@media (min-width: 530px) and (max-width: 600px) {
  /* body {
    width: 180%;
  } */

  .header {
    padding: 5px;
  }

  .header-right,
  .header-left {
    font-size: 16px;
  }

  .chat-page-container {
    flex-direction: row;
    height: auto;
  }

  .chats-container {
    width: 45%;
    margin: 1%;
  }

  .chat-window-container {
    margin: 1%;
  }

  .message{
    max-width: 50%;
  }

  .header-button {
    padding: 10px 20px;
  }

  .add-admin-form {
    width: 60%;
  }

  .add-admin-form .x-button {
    top: 3px;
    left: -40px;
  }

  .password-input-container input {
    width: 39%;
  }
  
  .password-input-container button {
    width: 50%;
  }

  .change-admin-form {
    width: 80%;
  }

  .file-preview-form {
    top: 50%;
    left: 50%; 
  }

  .submission-form{
    top: 25%;
    left: 25%;
  }

  .submission-form .x-button {
    top: 8px;
    left: -40px;
  }

  .submission-form select {
    width: 65%;
  }

  .submission-form button {
    width: calc(40% - 5px);
  }

  .chat-input-container input{
    width: 57%;
  }

  .file-preview-form .x-button {
    top: 10px;
    left: -50px;
  }
}



@media (min-width: 601px) and (max-width: 1024px) {
  body {
    /* width: 180%; */
  }

  #messageRef {
    display: none;
  }

  .header {
    padding: 5px;
  }

  .header-right,
  .header-left {
    font-size: 16px;
  }

  .chat-page-container {
    flex-direction: row;
    height: auto;
  }

  .chats-container {
    width: 45%;
    margin: 1%;
  }

  .chat-window-container {
    margin: 1%;
  }

  .add-admin-form {
    width: 60%;
  }

  .header-button {
    padding: 10px 20px;
  }

  .file-preview-form {
    top: 50%;
    left: 50%; 
  }

  .submission-form{
    top: 25%;
    left: 25%;
  }

  .submission-form select {
    width: 75%;
  }

  .submission-form .x-button {
    top: 8px;
    left: -40px;
  }

  .submission-form select {
    width: 75%;
  }

  .submission-form button {
    width: calc(40% - 5px);
  }

  .chat-input-container input{
    width: 70%;
  }



}

